@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: SourceSansPro;
  src: url(./assets/fonts/SourceSansPro-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap
}



@font-face {
  font-family: SourceSansPro;
  src: url(./assets/fonts/SourceSansPro-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: SourceSansPro;
  src: url(./assets/fonts/SourceSansPro-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

/* 
@font-face {
  font-family: SFMono;
  src: url(./assets/fonts/SFMono-Regular.woff2);
  font-weight: 600;
  font-style: normal;
  font-display: swap
} */



body,
html {

  background-color: #0a182f;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}



* {
  font-family: 'SourceSansPro',
    sans-serif;
  margin: 0px;
  padding: 0px;

  scroll-margin-top: 24px;
}


.mouse {
  width: 25px;
  height: 40px;
  border: 2px solid #eee;
  border-radius: 60px;
  position: relative;
  overflow: hidden;
}

.mouse::before {
  content: '';
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #eee;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 1.3s infinite;
  -webkit-animation: wheel 1.3s infinite;
}



@keyframes wheel {
  to {
    opacity: 0;
    top: 27px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 27px;
  }
}